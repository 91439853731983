.filter-bar{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
}

.filter-bar-item{
  margin: 10px 10px;
  height: 40px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.294);
  border-radius: 5px;
  border: none;
  min-width: 300px;
}