.spinner {
  width: 80px;
  height: 80px;
  border: 2px solid var(--light-grey); /* Light grey */
  border-top: 8px solid var(--light-blue);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinner-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}