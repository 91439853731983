.navbar{
  position: relative;
  background-color: var(--light-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: var(--dark-blue);
  padding: 0px 10px;
  z-index: 1000;
  box-shadow: 0px 2px 16px rgba(63, 114, 175, .64);
}

.nav span, .nav{
  display: flex;
  align-items: center;
}

.nav p{
  padding: 9px 15px;
  cursor: pointer;
  font-weight: bold;
  color: var(--dark-blue);;
}

.nav p:hover{
  background-color: var(--light-blue);
  color: var(--off-white);
}

#nav-name { 
  color: var(--light-blue);
  font-weight: bold;
}
